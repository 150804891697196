<template>
  <b-card title="Edit Project Warranty">
    <b-col cols="12">
      <label>Name<span style="color:red">(*)</span></label>
      <b-form-input
        placeholder="Project Name"
        v-model="project.name"
      ></b-form-input>
    </b-col>

    <b-col cols="12">
      <label>Address<span style="color:red">(*)</span></label>
      <b-form-input
        placeholder="Project Address"
        v-model="project.address"
      ></b-form-input>
    </b-col>
    <b-col cols="12">
      <label>Postal<span style="color:red">(*)</span></label>
      <b-form-input
        placeholder="Project Postal Code"
        v-model="project.postalCode"
      ></b-form-input>
    </b-col>
    <b-col cols="12">
      <label>Country<span style="color:red">(*)</span></label>
      <b-form-input
        list="list-country"
        placeholder="Developer Country"
        v-model="project.country"
      ></b-form-input>
      <b-form-datalist
        id="list-country"
        :options="projectCountry"
      ></b-form-datalist>
    </b-col>

    <b-col cols="12">
      <label>Developer<span style="color:red">(*)</span></label>
      <b-form-input
        v-model="project.developer"
        placeholder="Developer Name"
      ></b-form-input>
    </b-col>

    <b-col cols="12">
      <label>Address 1<span style="color:red">(*)</span></label>
      <b-form-input
        v-model="project.devAddress1"
        placeholder="Developer Address Line 1"
      ></b-form-input>
    </b-col>

    <b-col cols="12">
      <label>Address 2</label>
      <b-form-input
        v-model="project.devAddress2"
        placeholder="Developer Address Line 2"
      ></b-form-input>
    </b-col>

    <b-col cols="12">
      <label>Developer Postal<span style="color:red">(*)</span></label>
      <b-form-input
        placeholder="Developer Postal Code"
        v-model="project.devPostalCode"
      ></b-form-input>
    </b-col>
    <b-col cols="12">
      <label>Developer Country<span style="color:red">(*)</span></label>
      <b-form-input
        list="list-developer-country"
        placeholder="Developer Country"
        v-model="project.devCountry"
      ></b-form-input>
      <b-form-datalist
        id="list-developer-country"
        :options="devProjectCountry"
      ></b-form-datalist>
    </b-col>

    <b-col cols="12">
      <label>Warranty Duration<span style="color:red">(*)</span></label>
      <b-form-select
        v-model="project.warrantyDuration"
        :options="warrantyDurations"
      ></b-form-select>
    </b-col>

    <b-col cols="12">
      <label>Email<span style="color:red">(*)</span></label>
      <b-form-input
        placeholder="Project Email"
        type="email"
        v-model="project.email"
      ></b-form-input>
    </b-col>

    <b-col cols="12">
      <b-row class="hide-on-mobile">
            <!-- <b-col cols="6">
                <label>Start Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="project.startDate" class="mb-2"></b-form-datepicker>
            </b-col> -->

            <b-col cols="12">
                <label>TOP Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="project.topDate" class="mb-2"></b-form-datepicker>
            </b-col>
            </b-row>
            <b-row class="hide-on-desktop">
            <!-- <b-col cols="12">
                <label>Start Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="project.startDate" class="mb-2"></b-form-datepicker>
            </b-col> -->

            <b-col cols="12">
                <label>TOP Date <span style="color:red">(*)</span></label>
                <b-form-datepicker v-model="project.topDate" class="mb-2"></b-form-datepicker>
            </b-col>
            </b-row>
    </b-col>

    <!-- <b-col cols="12">
      <b-row class="hide-on-mobile">
            <b-col cols="10">
            <label>Delivery Order Number<span style="color:red">(*)</span></label>
            <b-form-input  v-model="delivOrder" placeholder="Delivery order" ></b-form-input>
            </b-col>
            <b-col cols="2">
                <b-button class="mt-2" variant="primary" @click="fillDo">Search</b-button>
            </b-col>
            </b-row>
            <b-row class="hide-on-desktop">
            <b-col cols="12">
            <label>Delivery Order Number<span style="color:red">(*)</span></label>
            <b-form-input  v-model="delivOrder" placeholder="Delivery order" ></b-form-input>
            </b-col>
            <b-col cols="2">
                <b-button class="mt-2" variant="primary" disabled @click="fillDo">Search</b-button>
            </b-col>
            </b-row>
    </b-col> -->

    <b-col cols="12" class="my-1">
      <b-table :items="project.selectedDo" :fields="doFields" show-empty>
        <template #cell(customer)="row">
          {{ row.item.CustomerName1 }} {{ row.item.CustomerName2 }}
        </template>
      </b-table>
    </b-col>


    <b-col cols="12" class="my-2 section-data">
      <h5>Product Registered</h5>
      <b-table :items="project.itemsProject" :fields="itemFields" show-empty responsive>
        <template #cell(generalEndWarranty)="row">
          {{ row.item.generalEndWarranty | formatDate }}
        </template>
        <template #cell(compressorEndWarranty)="row">
          {{ row.item.compressorEndWarranty?row.item.compressorEndWarranty:'-' | formatDate }}
        </template>
        <template #cell(residentialEndWarranty)="row">
          {{ row.item.residentialEndWarranty?row.item.residentialEndWarranty:'-' | formatDate }}
        </template>
      </b-table>
    </b-col>

    <b-col cols="12" class="mt-2">
      <b-button variant="success" @click="doUpdateProject">Update</b-button>
      <!-- <b-button @click="test">test</b-button> -->
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';
import { sleep,dateFormat }from '@/utils/utils.js'
export default {
    data() {
        return {
            delivOrder:'',
            project: {
                name: '',
                address: '',
                email: '',
                developer: null,
                saleOrder: null,
                topDate: null,
                warrantyDuration: null,
                postalCode:'',
                country: '',
                devAddress1:'',
                devAddress2:'',
                devPostalCode:'',
                devCountry:'',
                selectedDo:[],
                itemsProject:[]
            },
            warrantyDurations: [
                { value: 12, text: '12 Months' },
                { value: 15, text: '15 Month' },
                { value: 18, text: '18 Month' }
            ],
            doFields: [
                { key: 'sapDo', label: 'DO Number' },
                { key: 'sapSo', label: 'SO Number' },
                { key: 'dealerNo', label: 'Dealer Code' },
                // { key: 'action', label: 'Action' },      
            ],
            itemFields: [
                { key: 'itemModel', label: 'Model' },
                { key: 'serial', label: 'Serial' },
                { key: 'barcode', label: 'Barcode' },
                { key: 'pcode', label: 'Pcode' },
                { key: 'status', label: 'Status' },
                { key: 'sapDo', label: 'DO Number' },
                { key: 'generalEndWarranty', label: 'Developer Warranty End' },
                { key: 'compressorEndWarranty', label: 'Compressor Warranty End' },      
                { key: 'residentialEndWarranty', label: 'Residential Warranty End' },
                
                
            ],
            projectCountry: ["Singapore"],
            devProjectCountry: ["Singapore"],
            selectedSaleOrder: null,
            doList: [],
        }
    },
    mounted() {


        this.getProject(this.$route.params.id).then((res) => {
            this.project = res;

        }).catch((e) => {
            console.log(e)
        });


    },
    methods: {
        ...mapActions({
            getProject: 'projectWarranty/getProject',
            updateProject: 'projectWarranty/updateProject',
            getListDo: 'projectWarranty/getListDo',
            searchSO: 'projectWarranty/searchSO'
        }),
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        // test(){
        //     console.log('hasil project',this.project)
        // },
        fillDo() {
            const sameDO= this.project.selectedDo.some(x => x.sapDo=== this.delivOrder)
            console.log({sameDO})
            if(sameDO) {
            this.$bvToast.toast("Cannot add same DO Number", {
                        title: "DO number has been added to the list ",
                        variant: "danger",
                        solid: true,
                    });
                
                this.delivOrder=''
            }
            if(!sameDO) {
          this.verifyDo({sapDo:this.delivOrder}).then((res) => {
                // console.log(res)
                this.project.selectedDo.push({
                    sapDo:res.sapDo,
                    sapSo:res.sapSo,
                    pcode:res.pcode,
                    dealerNo:res.dealerNo
                    })


                this.$bvToast.toast('Delivery Order Found', {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                this.delivOrder = ''
            }).catch((e) =>{
                console.log({e})
                this.$bvToast.toast(e.response.data.errors[0].message, {
                        title: "Failed",
                        variant: "danger",
                        solid: true,
                    });
                
                this.delivOrder=''
            })
        }           
                // console.log('selected SO',this.selectedSaleOrder)   
        },
        doUpdateProject() {
          let { _id } = this.project;
          delete this.project.selectedDo;
          delete this.project.itemsProject;
          delete this.project.updatedAt;
            axios.put(`sales-and-purchase/project-warranty/${_id}`,this.project).then(async (project) => {
              console.log({project})
              this.project = project;
              this.$bvToast.toast(`Update Project Warranty Successfully`, {
                title: "Success",
                variant: "success",
                solid: true,
              });
              await this.sleep(3000)
              this.$router.go(-1);
            }).catch((e) => {
                console.log({e});
                if(e.request.status === 400){
                    this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                });}
                else
                {this.$bvToast.toast(`${e.response.data.message}`, {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                    });}
            });
        }
    }
}
</script>
<style scoped>

.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 20px 0 20px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}

@media (max-width : 761px){
  .hide-on-mobile {display: none !important;}
}
@media (min-width : 762px){
  .hide-on-desktop {display: none !important;}
}
</style>
